export const CONDITION_TYPE_ALL = 0,
	CONDITION_TYPE_AND = 1,
	CONDITION_TYPE_OR = 2,
	
	STATISTICS_VALUETYPES_MEAN = 0,
	STATISTICS_VALUETYPES_SUM = 1,
	STATISTICS_VALUETYPES_COUNT = 2,
	
	STATISTICS_DATATYPES_DAILY = 0,
	STATISTICS_DATATYPES_FREQ_DISTR = 1,
	STATISTICS_DATATYPES_SUM = 2,
	STATISTICS_DATATYPES_XY = 3,
	
	STATISTICS_CHARTTYPES_LINE = 0,
	STATISTICS_CHARTTYPES_LINE_FILLED = 1,
	STATISTICS_CHARTTYPES_BARS = 2,
	STATISTICS_CHARTTYPES_SCATTER = 3,
	STATISTICS_CHARTTYPES_PIE = 4,
	
	STATISTICS_STORAGE_TYPE_TIMED = 0,
	STATISTICS_STORAGE_TYPE_FREQ_DISTR = 1,
	
	CONDITION_OPERATOR_EQUAL = 0,
	CONDITION_OPERATOR_UNEQUAL = 1,
	CONDITION_OPERATOR_GREATER = 2,
	CONDITION_OPERATOR_LESS = 3;