export const URL_ABOUT_ESMIRA_SOURCE = 'https://raw.githubusercontent.com/KL-Psychological-Methodology/ESMira/main/about/',
	URL_ABOUT_ESMIRA_STRUCTURE_JSON = URL_ABOUT_ESMIRA_SOURCE+"structure.json",
	URL_ABOUT_ESMIRA_JSON = URL_ABOUT_ESMIRA_SOURCE+"langs/%s.json",
	URL_RELEASES_LIST = "https://api.github.com/repos/KL-Psychological-Methodology/ESMira-web/releases?per_page=50",
	
	FILE_CHECK_HTACCESS = 'api/check_htaccess/check.php',
	FILE_ADMIN = 'api/admin.php',
	FILE_RESPONSES = FILE_ADMIN+"?type=get_data&study_id=%1&q_id=%2",
	FILE_MEDIA = FILE_ADMIN+"?type=create_mediaZip&study_id=%1",
	FILE_IMAGE = FILE_ADMIN+"?type=get_media&study_id=%1&userId=%2&entryId=%3&key=%4&media_type=image",
	FILE_AUDIO = FILE_ADMIN+"?type=get_media&study_id=%1&userId=%2&entryId=%3&key=%4&media_type=audio",
	FILE_SAVE_ACCESS = 'api/access.php',
	FILE_LEGAL = 'api/legal.php',
	FILE_GET_QUESTIONNAIRE = 'api/questionnaire.php?id=%d1&qid=%d2&access_key=%s1&lang=%s2&%s3',
	FILE_SAVE_DATASET = 'api/datasets.php',
	FILE_SERVER_STATISTICS = 'api/server_statistics.php',
	FILE_STATISTICS = 'api/statistics.php?id=%d&access_key=%s',
	FILE_STUDIES = 'api/studies.php';